import React from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu as M } from "antd";
import { useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: "fmb",
    label: "FMB",
    children: [
      { key: "menu", label: "Menu" },
      { key: "feedback", label: "Feedbacks" },
      { key: "items", label: "Items" },
      { key: "allergens", label: "Allergens" },
      { key: "donations", label: "Donations" },
    ],
  },
  {
    type: "divider",
  },
  {
    key: "sabil",
    label: "Sabil",
    children: [
      // { key: "payments", label: "Payments" },
      { key: "takhmeen", label: "Takhmeen" },
      { key: "edit", label: "View Sabil" },
      // { key: "report", label: "Reports" },
    ],
  },
  {
    type: "divider",
  },
  {
    key: "notifications",
    label: "Notifications",
    children: [
      { key: "rsvp", label: "RSVP" },
      { key: "notify", label: "Notify" },
    ],
  },
  {
    type: "divider",
  },
  {
    key: "umoor",
    label: "Umoor",
    children: [{ key: "members", label: "Members" }],
  },
  {
    type: "divider",
  },
  {
    key: "help",
    label: "Help",
    children: [{ key: "user-support", label: "User Support" }],
  },
  {
    type: "divider",
  },
  {
    key: "events",
    label: "Events",
    children: [{ key: "majlis", label: "Majlis" }],
  },
  {
    type: "divider",
  },
  // {
  //   key: "users",
  //   label: "Users",
  //   children: [
  //     { key: "list", label: "View" },
  //     { key: "add", label: "Add" },
  //   ],
  // },
  // {
  //   type: "divider",
  // },
  // {
  //   key: "admin",
  //   label: "Admin",
  //   children: [
  //     { key: "view", label: "View" },
  //     { key: "create", label: "Create" },
  //   ],
  // },
  // {
  //   type: "divider",
  // },
];

const Menu = ({
  menuOpen,
  setMenuOpen,
}: {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  const n = useNavigate();
  const navs = [
    { key: "menu", path: "/fmb/menu" },
    { key: "feedback", path: "/fmb/feedback" },
    { key: "items", path: "/fmb/items" },
    { key: "allergens", path: "/fmb/allergens" },
    { key: "donations", path: "/fmb/donations" },
    { key: "takhmeen", path: "/sabil/takhmeen" },
    { key: "edit", path: "/sabil/edit" },
    { key: "report", path: "/sabil/report" },
    { key: "list", path: "/users/list" },
    { key: "add", path: "/users/add" },
    { key: "view", path: "/admin/view" },
    { key: "create", path: "/admin/create" },
    { key: "notify", path: "/notifications/notify" },
    { key: "rsvp", path: "/notifications/rsvp" },
    { key: "members", path: "/umoor/members" },
    { key: "user-support", path: "/help/user-support" },
    { key: "majlis", path: "/events/majlis" },
  ];

  return (
    <M
      className="fixed h-screen left-0 bg-white z-10 lg:relative"
      onClick={(e) => navs.forEach((k) => k.key === e.key && n(k.path))}
      style={
        menuOpen
          ? { width: 256, overflowY: "auto", maxHeight: "calc(100vh - 60px)" }
          : { width: 0, overflow: "hidden" }
      }
      defaultOpenKeys={[window.location.pathname.split("/")[1] || ""]}
      defaultSelectedKeys={[window.location.pathname.split("/")[2] || ""]}
      mode="inline"
      items={items}
    />
  );
};

export default Menu;
